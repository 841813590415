import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

class LandingTextImage {

    animate()
    {

        gsap.set('.text-block-left .landing-page-text-image-element h2', {autoAlpha:0, x:"+=900"});
        gsap.set('.text-block-left .landing-page-text-image-element h2 .underline', {autoAlpha:0, x:"-=1200"});

        $(".landing-page-text-image.text-block-left").each(function ( index ) {

            let h2ElementID = "landing-page-text-image-text-block-left-h2-" + index;
            let h2hashElementID = "#landing-page-text-image-text-block-left-h2-" + index;
            let underlineElementID = "landing-page-text-image-text-block-left-underline-" + index;
            let underlineHashElementID = "#landing-page-text-image-text-block-left-underline-" + index;

            $('h2', this).attr('id', h2ElementID);
            $('.underline', this).attr('id', underlineElementID);

            let id = "landing-page-text-image-left-" + index
            $(this).attr('id', id);
            let hash = "#landing-page-text-image-left-" + index
            let tl = gsap.timeline({})

            // The animation on scroll down the page

            ScrollTrigger.create({
                animation: tl,
                trigger: hash,
                start: "top 60%",
                toggleActions: "restart none none reverse",
            })

            // Resets the animation when scroll back up

            // ScrollTrigger.create({
            //     animation: tl,
            //     trigger: hash,
            //     start: "top bottom",
            //     toggleActions: "none none none reset",
            //     //markers: true
            // })

            tl.to(h2hashElementID, {duration:0.25, autoAlpha:1})
                .to(h2hashElementID, {duration: 0.75, x: 0}, 0)
                .to(underlineHashElementID, {duration:0.25, autoAlpha:0.5}, 0)
                .to(underlineHashElementID, {duration: 0.75, x: 0}, 0)
            ;
        })
    }

    constructor()
    {

        this.animate()

    }

}

export default LandingTextImage
