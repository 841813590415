import { gsap } from 'gsap';
import { CustomEase } from "gsap/CustomEase";
class LandingPageHeader {

    constructor()
    {
        $(".landing-page-header").each(function (index) {

            gsap.registerPlugin(CustomEase);

            let bounce = "M0,0,C-0.01,0,0,0,0.418,1,0.514,0.918,0.742,0.95,0.774,0.998,0.87,0.96,1,1,1,1";

            gsap.set("#animation", {opacity:0});
            gsap.set("#line1", {opacity:1, y:"-=330", x :"+=210"});
            gsap.set("#line2", {opacity:1, y:"+=330", x :"-=210"});
            gsap.set("#standards", {opacity:1, y:"-=330", x :"+=210"});
            gsap.set("#raised", {opacity:1, y:"+=330", x :"-=210"});

            gsap.set("#expectations", {opacity:1, y:"+=330", x :"-=210"});
            gsap.set("#sights", {opacity:1, y:"+=330", x :"-=210"});
            gsap.set("#game", {opacity:1, y:"+=330", x :"-=210"});

            let tl = gsap.timeline({repeat: -1});

            tl
                .set({}, {}, 0.75)
                .to("#animation", {duration: 0.5, opacity: 1})
                .to("#line1", {duration: 0.5,y: "+=330", x: "-=210", ease: "sine.easeOut"})
                .to("#line2", {duration: 0.5,y: "-=330", x: "+=210", ease: "sine.easeOut"}, "-=0.5")
                .to("#standards", {duration: 0.8, ease: CustomEase.create("custom", bounce), y: "+=330", x: "-=210"})
                .to("#raised", {duration: 0.8, ease: CustomEase.create("custom", bounce), y: "-=330", x: "+=210"})
                .to("#standards",{duration: 0.5, ease: "sine.easeIn", y: "-=330", x: "+=210", delay: 1.5})

                .to("#line1",{duration: 0.5, x: "+=55", ease: "sine.easeInOut", delay: 0.5})
                .to("#line2",{duration: 0.5, x: "+=55", ease: "sine.easeInOut"}, "-=0.5")
                .to("#raised", {duration: 0.5,x: "+=55", ease: "sine.easeInOut"}, "-=0.5")
                .to("#expectations", {
                    duration: 0.8,
                    ease: CustomEase.create("custom", bounce),
                    y: "-=330",
                    x: "+=210",
                    delay: 0.5
                })
                .to("#expectations", {duration: 0.5, ease: "sine.easeIn", y: "-=330", x: "+=210", delay: 1.5})

                .to("#line1", {duration: 0.5, x: "-=150", ease: "sine.easeInOut", delay: 0.5})
                .to("#line2", {duration: 0.5, x: "-=150", ease: "sine.easeInOut"}, "-=0.5")
                .to("#raised", {duration: 0.5, x: "-=150", ease: "sine.easeInOut"}, "-=0.5")
                .to("#sights", {duration: 0.8, ease: CustomEase.create("custom", bounce), y: "-=330", x: "+=210", delay: 0.5})
                .to("#sights",  {duration: 0.5, ease: "sine.easeIn", y: "-=330", x: "+=210", delay: 1.5})


                .to("#line1",  {duration: 0.5, x: "-=20", ease: "sine.easeInOut", delay: 0.5})
                .to("#line2", {duration: 0.5, x: "-=20", ease: "sine.easeInOut"}, "-=0.5")
                .to("#raised", {duration: 0.5, x: "-=20", ease: "sine.easeInOut"}, "-=0.5")
                .to("#game", {duration: 0.8, ease: CustomEase.create("custom", bounce), y: "-=330", x: "+=210", delay: 0.5})

                .addLabel("fadeout", "+=3")

                .to("#game",  {duration: 0.5, opacity: 0}, "fadeout")
                .to("#line1",  {duration: 0.5, opacity: 0}, "fadeout")
                .to("#line2",  {duration: 0.5, opacity: 0}, "fadeout")
                .to("#raised",  {duration: 0.5, opacity: 0}, "fadeout")

            ;

            //tl.play()

        })
    }
}

export default LandingPageHeader
