import $ from "jquery";
import { gsap } from "gsap";
import ContactMap from "./ContactMap";

class PopupWidget {

    constructor()
    {

        $("#contact-widget").each(function (index) {

            var content = $('.content', this);

            $('#popup-open').click(function () {

                gsap.set(content, {display: "block"});

            });

            $('#popup-close').click(function () {

                gsap.set(content, {display: "none"});

            });

            $("#contact-widget a").click(function (e) {



                // event.preventDefault(e);
                //
                // //let tohref = $(this).attr("href");
                // let title = $(this).attr("title");
                //
                // console.log('title ', title)
                //
                // window.gtag('event', 'widget_click', {
                //     'app_name': 'MB92HomeWidget',
                //     'screen_name': title
                // });
                //
                // onClick="ga('send','event','MB92HomeWidget','widget_click', 'my-title',10);"
                //window.location=tohref;

              //TweenMax.set(content, {display: "none"});

            });

        });
    }
}

export default PopupWidget

