import $ from "jquery";
import { gsap } from "gsap";
import Swiper from '../autoload/swiper-bundle.js';

class FacilitiesMap {

    constructor()
    {

        var swiperArray = [];

        $(".facilities-swiper").each(function ( index ) {

          //var thumbSwiperID = "#t" + index;
          // var gallerySwiperID = "#g" + index;
          // var galleryNext = ".n" + index;
          // var galleryPrev = ".p" + index;

            var gallerySwiperID = "#swiper-" + index;

            var thumbsWrapper = "#thumbs-" + index;

            console.log("gallerySwiperID " + gallerySwiperID);

            var gallery = new Swiper(gallerySwiperID, {
              //nextButton: galleryNext,
              //prevButton: galleryPrev,
              //spaceBetween: 10,
              //effect: 'fade',
              //loop : true
            });

            swiperArray.push(gallery);

            $(".thumb", thumbsWrapper).each(function ( index ) {

                $('.thumb', thumbsWrapper).click(function ( e ) {


                    var id = $(this).data('slide');

                    console.log("id " + id);

                    gallery.slideTo(id, 500);

                });
            });
        });

      //$(".thumb").on('click', 'div', function(){

      // get swiper ID
      // get slide ID
      //mySwiper.slideTo($(this).index(), 500);
      //});

        if ( $('.section-facilities').length ) {
            $('.marker').click(function (e) {

                console.log("marker click");

                var ID = $(this).attr("data-slideID");
                var swiperID = "#swiper-" + ID;
                var thumbsID = "#thumbs-" + ID;
                var textID = "#t-" + ID;

                console.log("marker " + ID);

                gsap.set(".facilities-swiper", {autoAlpha: 0});
                gsap.set(swiperID, {autoAlpha: 1});

                gsap.set(".thumbs", {autoAlpha: 0});
                gsap.set(thumbsID, {autoAlpha: 1});

                var popup = ".popup";
                var inner = $(".container", popup);

                gsap.set(".pop-up-text", {autoAlpha: 0});
                gsap.set(textID, {autoAlpha: 1});

                gsap.set(popup, {autoAlpha: 0});
                gsap.to(popup, 0.5, {autoAlpha: 1});

                gsap.set(inner, {autoAlpha: 0, y: 50});
                gsap.to(inner, 0.5, {autoAlpha: 1, y: 0});

            });

            $('.popup-close').click(function (e) {

                gsap.set(".popup", {autoAlpha: 0});

            });
        }

    }


}

export default FacilitiesMap
