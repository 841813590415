import $ from "jquery";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "../../gsap-shockingly-green/src/DrawSVGPlugin.js";
let imagesLoaded = require('imagesloaded');


class Services {

    constructor()
    {
        console.log("------------------ services")

        imagesLoaded.makeJQueryPlugin($);
        gsap.registerPlugin(DrawSVGPlugin,);

        $('#featured-services').imagesLoaded(function () {

            gsap.set(".white", {drawSVG: 0});

            gsap.set(".single-featured-service .overlay", {x: -800});

            $(".single-featured-service").each(function (index) {

                $(this).mouseover(
                    function () {

                        console.log("mouse over");

                        var t = "#t-" + index;
                        var i = "#i-" + index + " .white";

                        console.log("i " + i);

                        gsap.killTweensOf(t);
                        gsap.killTweensOf(i);
                        gsap.to(t,  {x: "0%", duration: 0.4});
                        gsap.to(i,  {drawSVG: "100%",   ease: 'power1.inOut', duration: 0.5, delay: 0.5});
                    }
                ).mouseout(
                    function () {

                        console.log("mouse out");

                        var t = "#t-" + index;
                        var i = "#i-" + index + " .white";

                        gsap.killTweensOf(t);
                        gsap.killTweensOf(i);
                        gsap.to(i,  {drawSVG: "0%",  ease: 'power1.inOut', duration: 0.4, });
                        gsap.to(t,  {x: "-800", delay: 0.4, duration: 0.4});

                    }
               );
            });

        });
    }

}

export default Services
