//import Swiper from 'swiper/swiper-bundle';
import $ from "jquery";
//import Swiper from 'swiper/swiper-bundle.js';
import Swiper from '../autoload/swiper-bundle.js';

class ShipyardCarousel {

    constructor() {

        console.log('ShipyardCarousel')

        let swiperWrapperClass = '.shipyard-container';

      $(swiperWrapperClass).each(function( index ) {

        var swiperID = 'sh-' + index;
        var swiperIDHash = '#sh-' + index;
        var prevIDHash = '#sh-' + index + '-prev';
        var nextIDHash = '#sh-' + index + '-next';

        $(this).attr('id', swiperID);

        // $('.swiper-button-next', this).attr('id', nextIDHash);
        // $('.swiper-button-prev', this).attr('id', prevIDHash);

        var homeSwiper = new Swiper(swiperIDHash + ' .swiper-container', {
          slidesPerView: 1,
          speed: 700,

          spaceBetween: 30,
          breakpoints: {
            1140: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            992: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            670: {
              slidesPerView: 1,
              spaceBetween: 30
            },

          },
          navigation: {
            nextEl: swiperIDHash + ' .swiper-button-next',
            prevEl: swiperIDHash + ' .swiper-button-prev'
          },

        });

        homeSwiper.on('transitionStart', function () {

          //console.log('transitionStart');

        });

      })

    }


}

export default ShipyardCarousel
