class Size {

    constructor() {

        console.log('size constructor')

    }

    getWindowSize() {
        console.log('size getWindowSize')

        if (document.body && document.body.offsetWidth) {
            this.winW = document.body.offsetWidth;
            this.winH = document.body.offsetHeight;
        }
        if (document.compatMode === 'CSS1Compat' && document.documentElement && document.documentElement.offsetWidth) {
            this.winW = document.documentElement.offsetWidth;
            this.winH = document.documentElement.offsetHeight;
        }
        if (window.innerWidth && window.innerHeight) {
            this.winW = window.innerWidth;
            this.winH = window.innerHeight;
        }

        return {width: this.winW, height: this.winH};
    }

    /*getWindowSize = function () {
        if (document.body && document.body.offsetWidth) {
            this.winW = document.body.offsetWidth;
            this.winH = document.body.offsetHeight;
        }
        if (document.compatMode === 'CSS1Compat' && document.documentElement && document.documentElement.offsetWidth) {
            this.winW = document.documentElement.offsetWidth;
            this.winH = document.documentElement.offsetHeight;
        }
        if (window.innerWidth && window.innerHeight) {
            this.winW = window.innerWidth;
            this.winH = window.innerHeight;
        }

        return {width: this.winW, height: this.winH};
    };

    getWindowWidth = function () {

        if (document.body && document.body.offsetWidth) {
            this.winW = document.body.offsetWidth;
        }
        if (document.compatMode === 'CSS1Compat' && document.documentElement && document.documentElement.offsetWidth) {
            this.winW = document.documentElement.offsetWidth;
        }
        if (window.innerWidth && window.innerHeight) {
            this.winW = window.innerWidth;
        }

        return {width: this.winW};
    }

    getWindowHeight = function () {

        if (document.body && document.body.offsetWidth) {
            this.winH = document.body.offsetHeight;
        }
        if (document.compatMode === 'CSS1Compat' && document.documentElement && document.documentElement.offsetWidth) {
            this.winH = document.documentElement.offsetHeight;
        }
        if (window.innerWidth && window.innerHeight) {
            this.winH = window.innerHeight;
        }

        return {height: this.winH};
    }

    getDocHeight = function () {
        var D = document;
        return Math.max(
            Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
            Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
            Math.max(D.body.clientHeight, D.documentElement.clientHeight)
        );
    };

    checkSize = function () {

        var d = this.getWindowSize();

        this.winW = d.width;
        this.winH = d.height;

    };*/
}

export default Size
