import $ from "jquery";
import 'select2';
import Isotope from 'isotope-layout/dist/isotope.pkgd.js';

class Careers {

    constructor()
    {
        let self = this;

        console.log("************** Careers");

        if ($('.archive-careers').length) {
            console.log('careers with select f')

            self.$company = $("#company");
            self.$company.select2({minimumResultsForSearch: -1});

            self.$department = $("#department");
            self.$department.select2({minimumResultsForSearch: -1});

            self.$contract = $("#contract");
            self.$contract.select2({minimumResultsForSearch: -1});


        self.$company.on("change", function (e) {



            checkValues();

            console.log("company change");
          //  self.filterYachts();

        });
        self.$department.on("change", function (e) {

            console.log("department change");

            checkValues();
            //self.filterYachts();

        });

        self.$contract.on("change", function (e) {

            console.log("$contract change");

            checkValues();
            //self.filterYachts();

        });

        // function checkValues()
        // {
        //     console.log("--------- checkValues 1");
        // }

        let selectorID = document.querySelector('#positions');

        let positions = new Isotope(selectorID, {
            itemSelector: '.position',
          });

      // self.$grid = $('.grid').isotope({
      //   itemSelector: '.position'
      //
      // });
      //   let selectorID = document.querySelector('#positions');
      //
      //   let positions = new Isotope(selectorID, {
      //       itemSelector: '.position',
      //   });
      //
        function checkValues()
        {

            console.log("--------- checkValues");

            let company = self.$company.val();

            if (company) {
                company = "." + company;
            }

            let department = self.$department.val();

            if (department) {
                department = "." + department;
            }

            let contract = self.$contract.val();

            if (contract) {
                contract = "." + contract;
            }

          let filter = company + department + contract;

            console.log("filter ", filter)


            positions.arrange(
                {
                    filter: filter,}
            )

        }
        }
    }
}

    export default Careers
