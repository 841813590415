import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.js';

class SignupModal {

    constructor()
    {

        console.log('SignupModal')

        $(".sign-up a").click(function (e) {

            e.preventDefault()

            showModal()
        });


        $("#signup-modal .close").click(function (e) {

            e.preventDefault()

            hideModal()
        });

        function showModal()
        {

            $('#signup-modal').modal('show')
        }

        function hideModal()
        {

            $('#signup-modal').modal('hide')
        }

    }
}
export default SignupModal
