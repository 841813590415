import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

class TimelineInline {

    constructor()
    {
        gsap.registerPlugin(ScrollTrigger);
        let count = 0

        $(".timeline-inline").each(function (index) {

            if (!$(this).hasClass("static")) {

                let id = "timeline-inline-" + count
                $(this).attr('id', id);
                let hash = "#timeline-inline-" + count
                let tl = gsap.timeline({})

                ScrollTrigger.create({
                    animation: tl,
                    trigger: hash,
                    start: "top center",
                    toggleActions: "restart none none reverse",
                    //markers: true
                })

                // ScrollTrigger.create({
                //     animation: tl,
                //     trigger: hash,
                //     start: "top bottom",
                //     toggleActions: "none none none reset",
                // })

                tl.pause()

                let element = hash + ' .timeline-inline-element'

                tl.to(element, {opacity: 1, y: 0, duration: 0.75, ease: "sine.inOut"})

                count++;
            } else {
                console.log('static')
            }

        });

    }

}

export default TimelineInline
