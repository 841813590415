import $ from "jquery";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "../../gsap-shockingly-green/src/DrawSVGPlugin.js";
let imagesLoaded = require('imagesloaded');
import Size from './size';

class Menu {

    openMenu(scope)
    {

        scope.menuOpen = true;

        console.log('--------------- openMenu');

        let $subMenu = $(".sub-menu");
        $subMenu.removeClass('open');

        gsap.set($subMenu, {height: 0});

        if ( $('.page-content').hasClass('laciotat') ) {
            console.log('page content is laciotat');
        }
        if ( $('.page-content').hasClass('group') ) {
            console.log('page content is group');
        }
        if ( $('.page-content').hasClass('barcelona') ) {
            console.log('page content is barcelona');
        }

        $("#menu-wrapper").removeClass("menu-closed");
        $("#menu-wrapper").addClass("menu-open");
        $("#nav-bar").addClass("menu-open");

        gsap.set('.child-item', {autoAlpha:0});
        gsap.set('.menu-item', {autoAlpha:1});
        gsap.set('#search', {autoAlpha:1});
        //gsap.killChildTweensOf(document.getElementById("nav-bar"));
        scope.killChildTweensOf(document.getElementById("nav-bar"), false);
        gsap.set('#background', {x:"-100%"});
        gsap.set('#menu', {autoAlpha:0});
        gsap.set('#open-menu-logo', {autoAlpha:0});
        gsap.set('#close-menu', {autoAlpha:0});

        function done()
        {

            console.log("done");
        }

        if (scope.s.width > 768) {
            gsap.to('#background', {x:"-50%", onComplete:done,  ease: 'power1.inOut', duration:0.8});
        } else {
            gsap.to('#background',  {x:"0%", onComplete:done,  ease: 'power1.inOut', duration:0.8});
        }

        gsap.to('#open-menu-logo', {autoAlpha:1,delay:0.6, duration:0.5});
        gsap.to('#close-menu', {autoAlpha:1,delay:0.7, duration:0.5});
        gsap.to('#menu',  {autoAlpha:1,delay:0.8, duration:0.5});
        gsap.to("#shape",  {attr:{points:"0,0 0,1440 3690,1440 3690,0"},delay:0.5, ease: 'power1.inOut', duration:0.7});


    };

    closeMenu(scope)
    {

        console.log("close");

        $('body').removeClass("no-scroll");

      // self.$menuBurger.removeClass("open");

        function done()
        {

            scope.menuOpen = false;
            $("#menu-wrapper").removeClass("menu-open");
            $("#menu-wrapper").addClass("menu-closed");
            $("#nav-bar").removeClass("menu-open");

        }

        scope.killChildTweensOf(document.getElementById("nav-bar"), false);
        //gsap.killChildTweensOf(document.getElementById("nav-bar"));
        gsap.to('#open-menu-logo', {autoAlpha:0, duration:0.25});
        gsap.to('#close-menu', {autoAlpha:0, duration:0.25});
        gsap.to('#menu', {autoAlpha:0,  duration:0.25});
        gsap.to("#shape", {attr:{points:"0,0 0,1440 2776,1440 3690,0"}, ease: 'power1.inOut', duration:0.7});
        gsap.to('#background',  {x:"-100%", onComplete:done, delay:0.5, ease: 'power1.inOut', duration:0.85});


    };

    killChildTweensOf(parent, complete)
    {
        let parents = gsap.utils.toArray(parent),
        i = parents.length,
        _isDescendant = function (element) {
            while (element) {
                element = element.parentNode;
                if (element === parent) {
                    return true;
                }
            }
        },
        j, tweens, targets;
        if (i > 1) {
            while (--i > -1) {
                killChildTweensOf(parents[i], complete);
            }
            return;
        }
        parent = parents[0];
        tweens = gsap.globalTimeline.getChildren(true, true, false);
        for (i = 0; i < tweens.length; i++) {
            targets = tweens[i].targets();
            j = targets.length;
            for (j = 0; j < targets.length; j++) {
                if (_isDescendant(targets[j])) {
                    if (complete) {
                        tweens[i].totalProgress(1);
                    }
                    tweens[i].kill();
                }
            }
        }
    }

    showSubMenu($this)
    {

        console.log("showSubMenu");

        let self = this;

        console.log(" - " , $this.parent());

        if ( ! this.mobile) {
             let $openSubMenu = $(".sub-menu.open");
              gsap.set($openSubMenu, {height: "auto"});
              gsap.to($openSubMenu, {height: 0, ease: 'sine.inOut', duration:0.4});
              gsap.to($openSubMenu, {opacity: 0, duration:0.2});
            $openSubMenu.removeClass("open");
          // gsap.to($openSubMenu, {height: 0, ease: 'sine.inOut', duration:0.4});
          // gsap.to($openSubMenu, {opacity: 0, duration:0.2});
          //
          // $openSubMenu.removeClass("open");
            // // let $open$menuItems = $(".sub-menu.open .menu-item");

            // //
            // //
            // gsap.set($openSubMenu, {height: "auto"});
            // gsap.to($openSubMenu, {height: 0, ease: 'sine.inOut', duration:0.4});
            // gsap.to($openSubMenu, {opacity: 0, duration:0.2});
            //
            // $openSubMenu.removeClass("open");

            let $subMenu = $this.siblings(".sub-menu");
            let $menuItems = $this.siblings(".sub-menu .menu-item");

            if ($subMenu.hasClass('open')) {
               //gsap.set($subMenu, {height: "auto"});
               //gsap.to($subMenu, {height: 0, ease: 'sine.inOut', duration:0.4});
               //gsap.to($subMenu, {opacity: 0, duration:0.2});



            } else {




                gsap.set($subMenu, {height: "auto", opacity: 1});
                gsap.from($subMenu,  {height: 0, ease: 'sine.inOut', duration:0.2});

                gsap.set($menuItems, {x: -30, autoAlpha: 0});
                //gsap.staggerTo($menuItems, {autoAlpha: 1, x: 0, ease:  'sine.inOut', duration:0.3}, 0.1);

                //alert('addClass')

                $subMenu.addClass("open");
            }
        }

    };

    constructor()
    {
        console.log("------------------ services")

        let self = this;


        imagesLoaded.makeJQueryPlugin($);
        gsap.registerPlugin(DrawSVGPlugin,);

        const size = new Size();

        self.s = size.getWindowSize()

        console.log('s.width ' + self.s.width)

        $(window).resize(function () {

            self.s = size.getWindowSize()

        });

        $("#show-menu").click(function () {

            self.openMenu(self);

        });

        $("#close-menu").click(function () {

            self.closeMenu(self);

        });

        $('.menu-item.parent > a').click(function (e) {

            e.preventDefault();

            console.log("------------- sub menu-item.parent");

            let $this = $(this);

            self.showSubMenu($this);

        });

    }

}

export default Menu
