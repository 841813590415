import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import TimelineFullElement from "../util/TimelineFullElement";
import TimelineFullText from "../util/TimelineFullText";
import TimelineInline from "../util/TimelineInline";


class Timeline {


    refreshScrollTrigger()
    {
        ScrollTrigger.refresh();
    }

    constructor()
    {

        let self = this

        $('#landing-page').imagesLoaded()
            .always(function (  ) {
                console.log('all images loaded');
            })
            .done(function (  ) {
                console.log('all images preload images are successfully loaded');

                setTimeout(self.refreshScrollTrigger, 500)

            })
            .fail(function () {
                console.log('all images loaded, at least one is broken');
            })
            .progress(function ( instance, image ) {
                let result = image.isLoaded ? 'loaded' : 'broken';
                console.log('image is ' + result + ' for ' + image.img.src);
            });

        const timelineFullElement = new TimelineFullElement()
        const timelineFullText = new TimelineFullText()
        const timelineInline = new TimelineInline()

    }
}

export default Timeline
