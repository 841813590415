import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

class LandingLargeImage {

    animate()
    {

        gsap.set('.landing-page-large-image .top', {autoAlpha:0, x:"-=800"});
        gsap.set('.landing-page-large-image .bottom', {autoAlpha:0, x:"+=800"});

        $(".landing-page-large-image").each(function ( index ) {

            let triggerID = "landing-page-large-image-trigger-" + index;
            let elementID = "landing-page-large-image-element-" + index;

            let hashTriggerID = "#landing-page-large-image-trigger-" + index;
            let hashElementID = "#landing-page-large-image-element-" + index;

            $('.landing-page-large-image-trigger', this).attr('id', triggerID);
            $('.landing-page-large-image-element', this).attr('id', elementID);

            var topElementID = "landing-page-large-image-element-top-" + index;
            var topHashElementID = "#landing-page-large-image-element-top-" + index;

            $('.top', this).attr('id', topElementID);

            var bottomElementID = "landing-page-large-image-element-bottom-" + index;
            var bottomHashElementID = "#landing-page-large-image-element-bottom-" + index;

            $('.bottom', this).attr('id', bottomElementID);

            // $('h2', this).attr('id', h2ElementID);
            // $('.underline', this).attr('id', underlineElementID);
            //
            // let id = "landing-page-text-image-left-" + index
            // $(this).attr('id', id);
            // let hash = "#landing-page-text-image-left-" + index
            //
            //


            let tl = gsap.timeline({})

            // The animation on scroll down the page

            ScrollTrigger.create({
                animation: tl,
                trigger: hashTriggerID,
                start: "top 60%",
                toggleActions: "restart none none reverse",
            })

            // Resets the animation when scroll back up

            // ScrollTrigger.create({
            //     animation: tl,
            //     trigger: hashTriggerID,
            //     start: "top bottom",
            //     toggleActions: "none none none reset",
            //     //markers: true
            // })

            tl.to(topHashElementID, {duration:0.25, autoAlpha:0.6})
                .to(topHashElementID, {duration: 0.75, x: 0}, 0)
                .to(bottomHashElementID, {duration:0.25, autoAlpha:0.5}, 0)
                .to(bottomHashElementID, {duration: 0.75, x: 0}, 0)
            ;
        })
    }

    constructor()
    {

        this.animate()

    }

}

export default LandingLargeImage
