import AOS from 'aos';
import VideoPlayer from "../util/videoPlayer.js";
import Services from "../util/Services.js";
import Menu from "../util/Menu.js";
import ShipyardCarousel from "../util/ShipyardCarousel";
import FacilitiesMap from "../util/FacilitiesMap";
// import TimelineFullElement from "../util/timelineFullElement";
// import TimelineInline from "../util/timelineInline";
import ContactMap from "../util/ContactMap";
import Careers from "../util/Careers";
import SignupModal from "../util/SignupModal";
import PopupWidget from "../util/PopupWidget";
import Timeline from "../util/Timeline";
import LandingPage from "../util/LandingPage";

export default {
    init() {
        // JavaScript to be fired on all pages
       // const scrolltoAnchor = new ScrolltoAnchor();
    },
    finalize() {
         console.log('----------- common 2')
        AOS.init();
        const videoPlayer = new VideoPlayer()
        const services = new Services()
        const menu = new Menu()
        const shipyardCarousel = new ShipyardCarousel()
        const facilitiesMap = new FacilitiesMap()
        //const timelineFullElement = new TimelineFullElement()
        //const timelineInline = new TimelineInline()
        const contactMap = new ContactMap()
        const careers = new Careers()
        const signupModal = new SignupModal()
        const popupWidget = new PopupWidget()
        // const landingPageHeader = new LandingPageHeader()
        // const landingTextImage = new LandingTextImage()
        const timeline = new Timeline()
        const landingPage = new LandingPage()
    },
};
