import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

class LandingFullImage {

    animate()
    {

        gsap.set('.landing-page-full-width-image .svg-image-overlay', {scaleY:0, transformOrigin:"50% 100%"});


        $(".landing-page-full-width-image").each(function ( index ) {

            var triggerID = "landing-page-full-width-image-trigger-" + index;
            var elementID = "landing-page-full-width-image-element-" + index;

            var hashTriggerID = "#landing-page-full-width-image-trigger-" + index;
            var hashElementID = "#landing-page-full-width-image-element-" + index;

            $('.landing-page-full-width-image-trigger', this).attr('id', triggerID);

            var svgElementID = "landing-page-full-width-image-element-svg-" + index;
            var svgHashElementID = "#landing-page-full-width-image-element-svg-" + index;

            $('.svg-image-overlay', this).attr('id', svgElementID);

            let tl = gsap.timeline({})

            // The animation on scroll down the page

            ScrollTrigger.create({
                animation: tl,
                trigger: hashTriggerID,
                start: "top center",
                toggleActions: "restart none none none",
                //markers: true
            })



            // Resets the animation when scroll back up

            ScrollTrigger.create({
                animation: tl,
                trigger: hashTriggerID,
                start: "top bottom",
                toggleActions: "none none none reset",
                //markers: true
            })

            tl.to(svgHashElementID, {duration:0.75, scaleY:1})

            ;
        })
    }

    constructor()
    {
        this.animate()

    }

}

export default LandingFullImage
