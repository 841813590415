import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

class TimelineFullElement {

    constructor()
    {

        gsap.registerPlugin(ScrollTrigger);

        let count = 0

        $(".timeline-full").each(function (index) {

            if (!$(this).hasClass("static")) {
                console.log('animated ')

                let id = "timeline-full-" + count
                $(this).attr('id', id);
                let hash = "#timeline-full-" + count

                let tl = gsap.timeline({})

                ScrollTrigger.create({
                    animation: tl,
                    trigger: hash,
                    start: "top center",
                    toggleActions: "restart none none reverse",
                    //markers: true
                })

                // ScrollTrigger.create({
                //     animation: tl,
                //     trigger: hash,
                //     start: "top bottom",
                //     toggleActions: "none none none reset",
                //     //markers: true
                // })

                tl.pause()

                let image = hash + ' .full-width-image'
                let text = hash + ' .full-text'

                gsap.set($(text).children(), {opacity: 0, y: 50})

                tl.to(image, {opacity: 1, x: 0, duration: 0.25, ease: "sine.inOut"})
                .to($(text).children(), {opacity: 1, y: 0, stagger: {each: 0.1}});

                count++;
            } else {
                console.log('static')
            }

        });

    }

}

export default TimelineFullElement
