import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import LandingPageHeader from "../util/LandingPageHeader";
import LandingTextImageRight from "../util/LandingTextImageRight";
import LandingTextImageLeft from "../util/LandingTextImageLeft";
import LandingLargeImage from "../util/LandingLargeImage";
import LandingFullImage from "../util/LandingFullImage";

class LandingPage {


    refreshScrollTrigger()
    {
        ScrollTrigger.refresh();
    }

    constructor()
    {

        let self = this

        $('#landing-page').imagesLoaded()
            .always(function (  ) {
                console.log('all images loaded');
            })
            .done(function (  ) {
                console.log('all images preload images are successfully loaded');

                setTimeout(self.refreshScrollTrigger, 500)

            })
            .fail(function () {
                console.log('all images loaded, at least one is broken');
            })
            .progress(function ( instance, image ) {
                let result = image.isLoaded ? 'loaded' : 'broken';
                console.log('image is ' + result + ' for ' + image.img.src);
            });

        const landingPageHeader = new LandingPageHeader()
        const landingTextImageLeft = new LandingTextImageLeft()
        const landingTextImageRight = new LandingTextImageRight()
        const landingLargeImage = new LandingLargeImage()
        const landingFullImage = new LandingFullImage()

    }
}

export default LandingPage
