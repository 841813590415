class ContactMap {

    constructor()
    {

        console.log("---------------------------- LoadContactMap JS");

        let pointArray = [];
        let markers = [];

        function startMap()
        {

            let mapOptions;

            console.log("---------------------------- startMap");

            $(".map-wrapper").each(function ( index ) {

                console.log("---------------------------- LoadContactMap = " + index);

                let id = $(this).attr("id");
                let ID = "#" + id;
                let container = $(".map-container", this).attr("id");

                console.log("id " + id);
                console.log("container " + container);

                let mapOptions;
                let MapPoints;

                let lat = $(ID).attr("data-lat");
                let lon = $(ID).attr("data-lon");
                let zoom = $(ID).attr("data-zoom");
                let pin = $(ID).attr("data-pin");
                zoom = Number(zoom);

                MapPoints = new google.maps.LatLng(lat,lon);

                mapOptions = {
                    zoom: zoom,
                    center: MapPoints,
                    disableDefaultUI: true,
                    panControl: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL,
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    },
                    mapTypeControlOptions: {
                        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'blue']
                    },
                    scaleControl: false,
                    scrollwheel: false,

                    styles: [
                    {
                        "featureType": "all",
                        "elementType": "all",
                        "stylers": [
                        {
                            "hue": "#e7ecf0"
                        }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                        {
                            "saturation": "-75"
                        },
                        {
                            "lightness": "-9"
                        },
                        {
                            "gamma": "1.58"
                        }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                        {
                            "saturation": -70
                        }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "on"
                        }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                        {
                            "visibility": "on"
                        }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "on"
                        }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "visibility": "on"
                        }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text",
                        "stylers": [
                        {
                            "visibility": "on"
                        }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.icon",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "saturation": -60
                        }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    }
                    ]

                };

                let GoogleMap = new google.maps.Map(document.getElementById(container), mapOptions);

                let icon = pin;

                console.log("icon ----------------- " + icon);

                let contactMapPoints = new google.maps.LatLng(41.372396,2.187740);

                let marker = new google.maps.Marker({
                    position: MapPoints,
                    map: GoogleMap,

                    icon: {
                      //url: location.protocol + '//' + location.host + '/pin.png',
                        url : pin,
                        size: new google.maps.Size(66, 66),

                        anchor: new google.maps.Point(0, 66),

                    }
                });

            });

            console.log("---------------------------- startMap function called");

        }

        function runAPITimercheck()
        {

            console.log("runAPITimercheck");

            console.log(window.data.apiLoaded);

            if (window.data.apiLoaded === true) {
                console.log("run map");

              // initMap();

                startMap();

              //run map
            } else {
                console.log("check map API again");

              // / setTimeout(runAPITimercheck, 500);
            }

        }

        runAPITimercheck();
    }

}

export default ContactMap
